import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SalesTable from "examples/Tables/SalesTable";

// Sales dashboard components

// Data
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

import { usePrivateGetRequestsMutation } from "reducers/endpoints";
import Stack from "@mui/material/Stack";

import Skeleton from "@mui/material/Skeleton";

function Prismatic() {
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] =
    useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] =
    useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) =>
    setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) =>
    setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) =>
    setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );
  let [privateGetRequests, { isLoading }] = usePrivateGetRequestsMutation();

  let [accountCounts, setAccountCounts] = useState([]);
  let [jobRates, setJobRates] = useState(null);
  let [errorLogs, setErrorLogs] = useState(null);
  let [reviews, setReviews] = useState(null);

  let [query, setQuery] = useState({
    offset: 1,
    pageSize: 10,
    sortBy: "jobs_last_7_days",
    sortOrder: "DESC",
  });

  useEffect(() => {
    let getStatistics = async () => {
      let response = await privateGetRequests({
        route: "/metric/statistic",
      });
      console.log("accounts:", response?.data?.data);
      setAccountCounts(response?.data?.data);
    };
    getStatistics();

    let getJobRates = async () => {
      let response = await privateGetRequests({
        route: "/metric/job-rates",
      });
      console.log("getJobRates:", response?.data?.data);
      setJobRates(response?.data?.data);
    };
    getJobRates();

    let getErrorLogs = async () => {
      let response = await privateGetRequests({
        route: "/metric/error-logs",
      });
      setErrorLogs(response?.data?.data);
    };
    getErrorLogs();

    let getReviews = async () => {
      let response = await privateGetRequests({
        route: "/metric/reviews",
      });
      setReviews(response?.data?.data);
      console.log("reviews:", response?.data?.data);
    };
    getReviews();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {accountCounts.length < 1 ? (
              <>
                {[1, 2, 3, 4].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    xl={2.4}
                    key={index}
                  >
                    <MDBox mb={1.5}>
                      <Stack spacing={1}>
                        {/* For variant="text", adjust the height via font-size */}
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={60}
                        />
                        <Skeleton variant="rounded" width={210} height={60} />
                      </Stack>
                    </MDBox>
                  </Grid>
                ))}
              </>
            ) : (
              accountCounts?.map((account, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon={account?.status?.toLowerCase()}
                      title={account?.status}
                      count={account?.count}
                    />
                  </MDBox>
                </Grid>
              ))
            )}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <MDBox pt={3} py={1}>
                {reviews ? (
                  <SalesTable
                    title="Reviews"
                    rows={reviews?.rows}
                    shadows={true}
                  />
                ) : (
                  <MDTypography variant="h6" fontWeight="medium">
                    {isLoading && (
                      <>
                        {" "}
                        <MDBox sx={{ width: 500, marginLeft: 10 }}>
                          <Skeleton />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation={false} />
                        </MDBox>
                      </>
                    )}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <Card>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Job Rates
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} py={1}>
                  {jobRates ? (
                    <DataTable
                      table={jobRates}
                      entriesPerPage={(true, { defaultValue: 5 })}
                      showTotalEntries={true}
                      isSorted={true}
                      noEndBorder
                      clickableCell={"name"}
                      handleClickableCell={(item) => {
                        const redirectUrl = `${process.env.REACT_APP_BOOLEAN_REVIEW_URL}/client_id/${item?.values?.id}/client_name/${item?.values?.name}`;
                        const link = document.createElement("a");
                        link.href = redirectUrl;
                        link.target = "_blank";
                        link.textContent = "Click Me";
                        link.click();
                      }}
                    />
                  ) : (
                    <MDTypography variant="h6" fontWeight="medium">
                      {isLoading && (
                        <>
                          {" "}
                          <MDBox sx={{ width: 500, marginLeft: 10 }}>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                          </MDBox>
                        </>
                      )}
                    </MDTypography>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={12}>
              <Card>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Error Logs
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} py={1}>
                  {errorLogs ? (
                    <DataTable
                      table={errorLogs}
                      entriesPerPage={(true, { defaultValue: 5 })}
                      showTotalEntries={true}
                      isSorted={true}
                      noEndBorder
                      clickableCell={"name"}
                      handleClickableCell={(item) => {
                        const redirectUrl = `${process.env.REACT_APP_BOOLEAN_REVIEW_URL}/client_id/${item?.values?.id}/client_name/${item?.values?.name}`;
                        const link = document.createElement("a");
                        link.href = redirectUrl;
                        link.target = "_blank";
                        link.textContent = "Click Me";
                        link.click();
                      }}
                    />
                  ) : (
                    <MDTypography variant="h6" fontWeight="medium">
                      {isLoading && (
                        <>
                          {" "}
                          <MDBox sx={{ width: 500, marginLeft: 10 }}>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                          </MDBox>
                        </>
                      )}
                    </MDTypography>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Prismatic;
